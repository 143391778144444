<template>
  <div v-if="loading" class="page-container">
    <div class="container-title-block">
      <div class="title-block-text">Топики</div>
      <div class="title-block-search">
        <SearchInput class="ml-4" v-model="search" label="Поиск топиков" />
      </div>
    </div>
    <div class="container-subtitle-block">
      <div>
        <v-btn
          @click="openDialog({}, true)"
          depressed
          class="button-white-blue"
        >
          <v-icon small>mdi-tag</v-icon
          ><span>Добавить топик</span>
        </v-btn>
      </div>
      <div class="d-flex align-center">
        <div class="subtitle-block-count">
          Всего топиков: {{ itemsAll.length }}
        </div>
      </div>
    </div>
    <div class="container-table">
      <Table
        :items="searchingItems"
        :headers="headers"
        headerFixed
        @click:row="openDialog"
      >
      </Table>
    </div>

    <Dialog
      v-model="dialogtopic"
      :titleText="topicDialogObj.titleDialog"
      :refForm="'formtopic'"
      @click:success="savetopic(selectedItem.id)"
      @click:delete="deleteTopic(selectedItem.id)"
      :buttonsFooter="{
        success: {
          title: topicDialogObj.textButton,
          loading: true,
        },
        delete: {
          title: selectedItem.id ? 'Удалить топик' : null,
        },
      }"
    >
      <template v-slot:blockMain>
        <div>
          <TextInput v-model="selectedItem.name" label="Название топика" valid />
        </div>
        <div class="dialog-items">
          <div class="dialog-items-values" ref="valuesBlock">
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script>
import SearchInput from "@/components/main/inputs/SearchInput";
import TextInput from "@/components/main/inputs/TextInput";

export default {
  components: { SearchInput, TextInput },
  data: () => ({
    loading: false,
    search: "",
    items: [],
    itemsAll: [],
    headers: [
      {
        text: "Название",
        align: "flex-left",
        sortable: false,
        value: "name",
      },
    ],
    headersConfirm: [
      {
        text: "",
        align: "flex-left",
        sortable: false,
        value: "title",
      },
    ],
    dialogtopic: false,
    dialogConfirm: false,
    topicDialogObj: {
      titleDialog: "",
      textButton: "Добавить топик",
    },
    dialogConfirmObj: {
      titleText: "",
      mainText: "",
      topic: {},
    },
    selectedItem: {
      values: [],
    },
  }),
  computed: {
    searchingItems() {
      return this.itemsAll.filter((i) =>
        i.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    getItems() {
      this.$axios
        .get(this.$store.getters.apiV7 + "/topics", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.itemsAll = res.data.data;
          this.loading = true;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    openDialog(item, create) {
      if (create) {
        if (item) {
          this.topicDialogObj = {
            titleDialog: "Добавление топика",
            textButton: "Добавить топик",
          };
        }
      } else if (item) {
          this.topicDialogObj = {
            titleDialog: "Редактирование топика",
            textButton: "Сохранить",
          };
        }
      if (!item.id) {
        item = [];
        item.name = "";
      }
      this.selectedItem = JSON.parse(JSON.stringify(item));
      this.dialogtopic = true;
    },
    filtertopicValues(topic) {
      this.selectedItem = this.selectedItem.filter(
        (i) => i.idNew != topic.idNew
      );
    },

    deleteTopic(id) {
      this.$axios
        .delete(this.$store.getters.apiV7 + `/topics/${id}`, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: id,
          },
        })
        .then(() => {
          this.dialogConfirm = true
          this.dialogtopic = false;
          this.getItems();
          })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    savetopic(id) {
      if(!id){
        this.$axios
        .post(
          this.$store.getters.apiV7 + "/topics",
          {
            name: this.selectedItem.name,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.getItems();
          this.dialogtopic = false;
          this.dialogConfirm = false;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
      } else {
        this.$axios
        .patch(
          this.$store.getters.apiV7 + `/topics/${id}`,
          {
            name: this.selectedItem.name,
            id: this.selectedItem.id
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.getItems();
          this.dialogtopic = false;
          this.dialogConfirm = false;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
      }
      
    },
  },
  created() {
    this.getItems();
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  padding: 40px 64px 0px 64px;
  height: 100%;
  width: 100%;
  position: relative;
  .container-title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-block-text {
      color: var(--app-black);
      font-size: 28px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0px;
    }
    .title-block-search {
      width: 300px;
      min-width: 300px;
    }
  }
  .container-subtitle-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 40px 0px 12px 0px;
    position: relative;
    .buttons-block-search {
      min-width: 300px;
    }
    .subtitle-block-count {
      font-size: 16px;
      color: var(--app-grey-3);
    }
  }
  .container-table {
    height: calc(100vh - 180px);
  }
  .dialog-items {
    .dialog-items-subheader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .subheader-text {
        font-size: 22px;
        font-weight: 600;
        color: var(--app-black);
      }
    }
    .dialog-items-values {
      max-height: 400px;
      overflow: auto;
      padding-top: 10px;
      -block {
        display: flex;
        justify-content: space-between;
        -block-icon {
          margin-left: 24px;
          margin-top: 10px;

          cursor: pointer;
        }
      }
    }
  }
  .dialog-text {
    font-weight: 500;
    color: var(--app-black);
    font-size: 14px;
  }
  .dialog-table-container {
    max-height: 400px;
  }
}
</style>